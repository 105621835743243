<template>
  <div class="relative flex flex-col gap-5 overflow-hidden">
    <h1
      v-if="isForIndex"
      class="text-tekstovyy text-6 lg:text-8.5 font-700"
    >
      {{ title }}
    </h1>
    <slot :classes="['text-#455862 text-3.75 inside-class lg:text-4.5 leading-1.45 [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-2 [&>ul]:mt-2 [&>ul]:mb-2 [&>ul]:pt-0 [&>ul]:pb-0 [&>ul]:list-style-disc! [&>ul>li]:text-3.75 [&>ul>li]:lg:text-4.5 [&>ul>li]:leading-1.45 [&>ul>li]:list-disc! [&>ul>li>a]:text-firmennyy [&>ul>li>a]:underline [&>p>a]:text-firmennyy [&>p>a]:underline  [&>h2]:font-700 [&>h2]:text-textovyy [&>h2]:text-7 [&>h2]:text-firmennyy [&>span]:font-500 [&>span]:text-firmennyy [&>a]:text-firmennyy [&>a]:underline ', { 'max-h-90 overflow-hidden': isClamp }]">
      <!-- @vue-ignore -->
      <p
        ref="contentRef"
        class="text-#455862 text-3.75 inside-class lg:text-4.5 leading-1.45 [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-2 [&>ul]:mt-2 [&>ul]:mb-2 [&>ul]:pt-0 [&>ul]:pb-0 [&>ul]:list-style-disc! [&>ul>li]:text-3.75 [&>ul>li]:lg:text-4.5 [&>ul>li]:leading-1.45 [&>ul>li]:list-disc! [&>ul>li>a]:text-firmennyy [&>ul>li>a]:underline [&>p>a]:text-firmennyy [&>p>a]:underline  [&>h2]:font-700 [&>h2]:text-textovyy [&>h2]:text-7 [&>h2]:text-firmennyy [&>span]:font-500 [&>span]:text-firmennyy [&>a]:text-firmennyy [&>a]:underline"
        :class="{ 'max-h-90 overflow-hidden': isClamp }"
      >
      <p>Сервис SakhFood позволяет наслаждаться блюдами из ресторанов, не выходя из дома или офиса. Мы связываем вас с лучшими заведениями Южно-Сахалинска и всего Сахалина, предлагая большой выбор и быструю доставку по всему городу.</p>

      <p>В каталоге ресторанов-партнеров представлены кухни со всего мира, включая европейскую, итальянскую, японскую и многие другие. Вы найдете как классические, так и авторские рецепты. Сотни разнообразных блюд и напитков можно заказать онлайн 24 часа в сутки, 7 дней в неделю.</p>

      <h2>Преимущества сервиса SakhFood</h2>
      <p>Почему заказать еду в Южно-Сахалинске с доставкой лучше у нас:</p>
      <ul>
        <li>круглосуточная служба поддержки;</li>
        <li>доступные цены;</li>
        <li>удобное приложение и сайт;</li>
        <li>простое оформление заказа;</li>
        <li>трекинг заказа на каждом этапе;</li>
        <li>постоянное обновление ассортимента.</li>
      </ul>

      <h2>Преимущества для поставщиков</h2>
      <p>Мы предлагаем выгодные условия для поставщиков:</p>
      <ul>
        <li>помощь в упаковке продукции;</li>
        <li>организация сервиса;</li>
        <li>отслеживание работы курьеров;</li>
        <li>понятная статистика, отчеты и аналитика продаж;</li>
        <li>поддержка и консультации по всем вопросам.</li>
      </ul>
      <p>За дополнительной информацией можно обращаться по номеру <a href="tel:+79140851155">+79140851155</a> или написать на почту <a href="mailto:Info@sakhfood.ru">Info@sakhfood.ru</a>.</p>

      <h2>Как сделать заказ</h2>
      <p>Чтобы купить готовую еду с доставкой на дом в Южно-Сахалинске через наше приложение или сайт:</p>
      <ul>
        <li>добавьте позиции в корзину;</li>
        <li>выберите способ оплаты (наличные, карта, онлайн-банкинг);</li>
        <li>заполните адрес и контактные данные.</li>
      </ul>
      <p>При необходимости, сотрудники ресторана свяжутся с вами для уточнения деталей заказа. Курьеры доставят его по указанному адресу. Выбирайте SakhFood и наслаждайтесь любимыми блюдами!</p>
      </p>
    </slot>
    <div
      v-if="isClamp && clampButtonVisible"
      class="absolute bottom-0 flex items-end justify-center w-full h-66 bg-gradient-to-b from-transparent to-77%"
      :class="color === 'white' ? 'to-white' : 'to-plashka-green'"
    >
      <Button
        variant="outline"
        class="w-full lg:w-fit"
        @click="isClampToglle"
      >
        Показать полностью
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">

defineProps({
  color: { type: String as PropType<'white' | 'green-light'>, default: 'white' },
  title: { type: String, default: 'Доставка еды 24 часа домой и в офис' },
  isForIndex: { type: Boolean, default: false }
});
const contentRef = defineModel<HTMLElement | undefined>();
const { isClamp, isClampToglle, clampButtonVisible } = useClamp(contentRef);
</script>
<style scoped lang="scss">
.inside-class:deep(ul) {
  list-style-type: disc!important;
  padding-left: 10px;
  list-style: lower-roman!important;
  li {
    position: relative;
    display: flex;
    &::before {
      content: '';
      display: flex;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-10px);
      background-color: rgb(80 168 10 / var(--un-text-opacity));
    }
  }
}

.inside-class ul {
  list-style-type: disc!important;
  padding-left: 10px;
  list-style: lower-roman!important;
  li {
    position: relative;
    display: flex;
    &::before {
      content: '';
      display: flex;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-10px);
      background-color: rgb(80 168 10 / var(--un-text-opacity));
    }
  }
}
</style>
